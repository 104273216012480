body {
  background: "#FAFAFA";
}
.example::-webkit-scrollbar {
  display: none;
}
.employee:hover {
  background: #faf5f5;
  border-radius: 10px !important;
}
.login {
  margin-right: 50px;
}
.maincontainer {
  display: flex;
  flex-direction: row;
}
.displayContainer {
  display: block;
}
@media only screen and (max-width: 600px) {
  .maincontainer {
    display: flex;
    flex-direction: column;
  }
  .login {
    margin-right: 0;
  }
  .displayContainer {
    display: none;
  }
}

.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10000; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;

  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  max-width: 400px;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
